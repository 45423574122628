import "core-js/modules/es.array.push.js";
import { defineComponent, toRefs, reactive, computed, watch, defineAsyncComponent } from 'vue';
import Eldialog from '@/components/Eldialog/index.vue';
import { ElMessageBox } from 'element-plus';
import { useDebounce } from '@/hooks/core/useDebounce';
import API from "@/api/studentCard/index";
import { useMessage } from '@/hooks/web/useMessage';
import day from "dayjs";
export default defineComponent({
  name: "BotherSet",
  components: {
    Eldialog,
    TreeSelect: defineAsyncComponent(() => import('@/components/TreeSelect/TreeSelect.vue')),
    TypeSelect: defineAsyncComponent(() => import('@/components/TypeSelect/TypeSelect.vue'))
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    imeiList: {
      type: Array,
      default: []
    }
  },
  emits: ['handleSuccess', 'update:visible'],
  setup(props, {
    emit
  }) {
    const message = useMessage();
    const refData = reactive({
      status: true,
      title: '静默状态设置',
      IMEI: '',
      detailList: [],
      list: []
    });
    watch(() => props.visible, val => {
      refData.status = true;
      refData.IMEI = '';
      refData.detailList = [];
      handleAddTime();
      refData.list = props.imeiList.length ? props.imeiList : [];
    });
    // 初始化表单
    const show = computed({
      get: () => props.visible,
      set: val => {
        emit('update:visible', val);
      }
    });
    //添加时间
    const handleAddTime = () => {
      refData.detailList.push({
        weeks: [],
        times: [new Date(day().format('YYYY-MM-DD 00:00')), new Date(day().format('YYYY-MM-DD 23:59'))]
      });
    };
    //删除时间
    const handleDelTime = active => {
      refData.detailList = refData.detailList.filter((item, index) => active != index);
    };
    //添加imei卡
    const handleAddImei = () => {
      try {
        if (refData.IMEI == null) throw {
          msg: 'IMEI号不能为空'
        };
        if (refData.IMEI == "") throw {
          msg: '请输入正确IMEI号'
        };
        if (refData.IMEI.length >= 20) throw {
          msg: 'IMEI号不能超过20个字节'
        };
        if (refData.list.includes(refData.IMEI)) throw {
          msg: 'IMEI号不能重复添加'
        };
        refData.list.push(refData.IMEI);
        refData.IMEI = null;
      } catch ({
        msg
      }) {
        message.warning(msg);
      }
    };
    //删除imei卡
    const handleDelImei = imei => {
      refData.list = refData.list.filter(item => imei != item);
    };
    //确定按钮
    const handleConfirm = () => {
      try {
        if (!refData.list.length) throw {
          msg: '请添加IMEI卡'
        };
        if (!refData.detailList.length) throw {
          msg: '请添加时间'
        };
        refData.status && refData.detailList.map((item, index) => {
          if (new Date(day(item.times[0]).format(`YYYY-MM-DD HH:mm`)).getTime() > new Date(day(item.times[1]).format(`YYYY-MM-DD HH:mm`)).getTime()) throw {
            msg: `第${index + 1}个开始时间不能大于结束时间`
          };
          if (!item.weeks.length) throw {
            msg: '请选择周期信息'
          };
        });
        // if(refData.detailList.some(item=>!item.weeks.length || item.times.some(val=>!val))  && refData.status)throw{msg:''};
        ElMessageBox.confirm(`是否确认设置当前“${refData.list.length}”个IMEI卡“${refData.status ? '开启' : '关闭'}”静默状态`, '提示', {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: refData.status ? "success" : "warning"
        }).then(async () => {
          const params = {
            mdtids: refData.list.join(','),
            disturbStatus: refData.status ? 1 : 2,
            detailList: refData.detailList.map(item => ({
              weeks: item.weeks.join('+'),
              times: item.times.map(val => day(val).format('HH:mm')).join('-')
            }))
          };
          const {
            code,
            msg
          } = await API.batchSendDisturb(params);
          code == 0 && (emit('handleSuccess'), emit('update:visible', false));
          message[code == 0 ? 'success' : 'warning'](code == 0 ? '设置成功' : msg);
        }).catch(() => {
          // ElMessage.info("已取消退出");
        });
      } catch ({
        msg
      }) {
        message.warning(msg);
      }
    };
    //防抖
    const [debounceHandleAddTime] = useDebounce(handleAddTime, 200);
    const [debounceHandleDelTime] = useDebounce(handleDelTime, 200);
    const [debounceHandleDelImei] = useDebounce(handleDelImei, 200);
    const [debounceHandleAddImei] = useDebounce(handleAddImei, 200);
    return {
      ...toRefs(refData),
      show,
      debounceHandleAddTime,
      debounceHandleDelTime,
      handleAddImei,
      handleConfirm,
      debounceHandleDelImei,
      debounceHandleAddImei
    };
  }
});